<template>
    <div class="wrapper">

        <Sidebar />
        <div class="main">
            <HorizBarre />

            <main class="content">
                <div class="container-fluid">

                    <div class="header">
                        <h1 class="header-title">
                            Liste des Appartements
                        </h1>
                    </div>


                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between align-items-center">
                                    <div>
                                        <h5 class="card-title">Liste des Appartements</h5>
                                        <h6 class="card-subtitle text-muted">Highly flexible tool that many advanced
                                        </h6>
                                    </div>

                                    <!-- Filtre par immeuble -->
                                    <div class="filter-building">
                                        <label for="filter-building" class="me-2">Filtrer par immeuble :</label>
                                        <select id="filter-building" v-model="selectedBuilding"
                                            @change="filterByBuilding">
                                            <option value="">Tous les immeubles</option>
                                            <option v-for="building in uniqueBuildings" :key="building.id"
                                                :value="building.name">
                                                {{ building.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>


                                <div class="card-body">
                                    <div v-if="appartments.isLoading" class="loading-container">
                                        <div class="spinner"></div>
                                        <p>Chargement en cours...</p>
                                    </div>

                                    <table v-else id="datatables-responsive" class="table table-striped"
                                        style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Immeuble</th>
                                                <th>Sous Contrat</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="appartement in filteredAppartments" :key="appartement.id">
                                                <td><h4>{{ appartement.building.buildingcode }} - {{ appartement.appartname }}</h4></td>
                                                <td><h4>{{ appartement.building.name }}</h4></td>
                                                <td>
                                                   
                                                        <h4 v-if="appartement.current_contract_id">
                                                         Locataire:  <span :class="{ 'text-success': appartement.current_solde >= 0, 'text-danger': appartement.current_solde < 0 }">  {{ appartement.current_contract.user.lastname  }} {{ appartement.current_contract.user.firstname  }} </span> <br>
                                                        </h4>
                                                        <h4 v-if="appartement.current_contract">Solde:
                                                            <span
                                                                :class="{ 'text-success': appartement.current_solde >= 0, 'text-danger': appartement.current_solde < 0 }">
                                                                {{ appartement.current_solde }} FCFA
                                                            </span>
                                                        </h4>
                                                        <h4  v-else>
                                                            <span class="warning"> Vacant</span>
                                                        </h4>
                                                    
                                                </td>
                                                
                                                <td>
                                                    <router-link
                                                        :to="{ name: 'Appartments.show', params: { id: appartement.id } }">
                                                        <button class="action-button view-button">Voir</button>
                                                    </router-link>
                                                    <button class="action-button edit-button" @click="editAppartement(appartement.id)">Edit</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>

            <FooterPage />

        </div>
    </div>

    <svg width="0" height="0" style="position:absolute">
        <defs>
            <symbol viewBox="0 0 512 512" id="ion-ios-pulse-strong">
                <path
                    d="M448 273.001c-21.27 0-39.296 13.999-45.596 32.999h-38.857l-28.361-85.417a15.999 15.999 0 0 0-15.183-10.956c-.112 0-.224 0-.335.004a15.997 15.997 0 0 0-15.049 11.588l-44.484 155.262-52.353-314.108C206.535 54.893 200.333 48 192 48s-13.693 5.776-15.525 13.135L115.496 306H16v31.999h112c7.348 0 13.75-5.003 15.525-12.134l45.368-182.177 51.324 307.94c1.229 7.377 7.397 11.92 14.864 12.344.308.018.614.028.919.028 7.097 0 13.406-3.701 15.381-10.594l49.744-173.617 15.689 47.252A16.001 16.001 0 0 0 352 337.999h51.108C409.973 355.999 427.477 369 448 369c26.511 0 48-22.492 48-49 0-26.509-21.489-46.999-48-46.999z">
                </path>
            </symbol>
        </defs>
    </svg>
</template>


<script>




// const url = process.env.VUE_APP_API_TRACKER_BASE_URL

import Sidebar from '../../components/Sidebar.vue'
import FooterPage from '../../components/Footer.vue'
import HorizBarre from '../../components/HorizBarre.vue'
import fetch from '../../utils/fetch';

export default {
    name: "HomePage",
    components: {
        Sidebar,
        FooterPage,
        HorizBarre
    },
    data() {
        return {
            user: null,
            appartments: {
                data: null,
                current: null,
                isUpdate: false,
                isLoading: true,
                isError: false,
                errorMessage: "",
            },
            selectedBuilding: "",  // Pour le filtre par immeuble

        };
    },
    computed: {
        uniqueBuildings() {
            if (!this.appartments.data) return [];
            const buildings = this.appartments.data.map(a => a.building);
            return Array.from(new Set(buildings.map(b => b.name)))
                .map(name => buildings.find(b => b.name === name));
        },

        filteredAppartments() {
            if (!this.selectedBuilding) {
                return this.appartments.data;
            }
            return this.appartments.data.filter(
                appartement => appartement.building.name === this.selectedBuilding
            );
        }
    },
    mounted() {

        this.getAppartement().then(() => {
            $("#datatables-responsive").DataTable({
                responsive: true,
                pageLength: 100,  // Pagination: 10 éléments par page
            });
        });
    },

    beforeUnmount() {
        $("#datatables-responsive").DataTable().destroy();
    },
    methods: {
        getAppartement() {
            this.appartments.isLoading = true;
            return fetch({ url: `appartments` }).then(({ data }) => {
                this.appartments.data = data.data ?? data;
                this.appartments.isError = false;
            }).catch(() => {
                this.appartments.isError = true;
                this.appartments.errorMessage = "Une erreur est survenue, réessayez";
                this.appartments.data = null;
            }).finally(() => {
                this.appartments.isLoading = false;
            });
        },
        filterByBuilding() {
            // Détruire et recréer le tableau pour appliquer le filtre
            $('#datatables-responsive').DataTable().destroy();
            this.$nextTick(() => {
                $("#datatables-responsive").DataTable({
                    responsive: true,
                    pageLength: 10,
                });
            });
        },
    }
}
</script>

<style>
.success {
    color: green;
}

.warning {
    color: red;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    font-size: 18px;
    color: #555;
}

.spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.action-button {
    padding: 8px 12px;
    margin-right: 5px;
    border: none;
    color: white;
    cursor: pointer;
}
.view-button { background-color: #3498db; }
.edit-button { background-color: #f39c12; }
</style>