<template>
    <div class="wrapper">

        <Sidebar />
        <div class="main">
            <HorizBarre />

            <main class="content">
                <div class="container-fluid">

                    <div class="header">
                        <h1 class="header-title">
                            Welcome back, {{ fullName }}!
                        </h1>
                        <!-- <p class="header-subtitle">You have 24 new messages and 5 new notifications.</p> -->
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <h5 class="card-title">Liste des Utilisateurs</h5>
                                    <h6 class="card-subtitle text-muted">Highly flexible tool that many advanced</h6>
                                </div>
                                <div class="card-body">
                                    <table id="datatables-reponsive" class="table table-striped" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>Nom</th>
                                                <th>Email</th>
                                                <th>Téléphone</th>
                                                <th>Age</th>
                                                <th>Start date</th>
                                                <th>Salary</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Tiger Nixon</td>
                                                <td>System Architect</td>
                                                <td>Edinburgh</td>
                                                <td>61</td>
                                                <td>2011/04/25</td>
                                                <td>$320,800</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>

            <FooterPage />

        </div>
    </div>

    <svg width="0" height="0" style="position:absolute">
        <defs>
            <symbol viewBox="0 0 512 512" id="ion-ios-pulse-strong">
                <path
                    d="M448 273.001c-21.27 0-39.296 13.999-45.596 32.999h-38.857l-28.361-85.417a15.999 15.999 0 0 0-15.183-10.956c-.112 0-.224 0-.335.004a15.997 15.997 0 0 0-15.049 11.588l-44.484 155.262-52.353-314.108C206.535 54.893 200.333 48 192 48s-13.693 5.776-15.525 13.135L115.496 306H16v31.999h112c7.348 0 13.75-5.003 15.525-12.134l45.368-182.177 51.324 307.94c1.229 7.377 7.397 11.92 14.864 12.344.308.018.614.028.919.028 7.097 0 13.406-3.701 15.381-10.594l49.744-173.617 15.689 47.252A16.001 16.001 0 0 0 352 337.999h51.108C409.973 355.999 427.477 369 448 369c26.511 0 48-22.492 48-49 0-26.509-21.489-46.999-48-46.999z">
                </path>
            </symbol>
        </defs>
    </svg>
</template>


<script>




// const url = process.env.VUE_APP_API_TRACKER_BASE_URL

import Sidebar from '../../components/Sidebar.vue'
import FooterPage from '../../components/Footer.vue'
import HorizBarre from '../../components/HorizBarre.vue'
import fetch from '../../utils/fetch';

export default {
    name: "HomePage",
    components: {
        Sidebar,
        FooterPage,
        HorizBarre
    },
    data() {
        return {
            user: null,
            users: {
                data: null,
                current: null,
                isUpdate: false,
                isLoading: true,
                isError: false,
                errorMessage: "",
            },

        };
    },
    computed: {
        // fullName() {
        //     return this.user ? `${this.user.firstname} ${this.user.lastname}` : 'User';
        // }
    },
    mounted() {
        // const userData = localStorage.getItem('user');
        // if (userData) {
        //     this.user = userData;
        // }

        $("#datatables-responsive").DataTable({
            responsive: true
        });

        this.getUsers()
    },

    beforeUnmount() {
        $("#datatables-responsive").DataTable().destroy();
    },
    methods: {
        getUsers() {
            this.users.isLoading = true;
            fetch({ url: `contracts` }).then(({ data }) => {
                this.users.data = data.data ?? data;
                this.users.isError = false;
            }).catch(() => {
                this.users.isError = true;
                this.users.errorMessage = "Une erreur est subvenue, Ressayez"
                this.users.data = null;

            }).finally(() => {
                this.users.isLoading = false;

            })
        },
    }
}
</script>

<style>
.success {
    color: green;
}

.warning {
    color: red;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    font-size: 18px;
    color: #555;
}

.spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

</style>