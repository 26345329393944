<template>
    <nav id="sidebar" class="sidebar">
        <router-link class="sidebar-brand" to="/">
            <svg>
                <use xlink:href="#ion-ios-building-strong"></use>
            </svg>
            ALIBORA - Ultrabail
        </router-link>

        <div class="sidebar-content">
            <div class="sidebar-user">
                <img src="/img/avatars/avatar.jpg" class="img-fluid rounded-circle mb-2" alt="Linda Miller" />
                <div class="fw-bold">{{ userName }} {{ lastname }}</div>
            </div>
            <ul class="sidebar-nav">
                <li class="sidebar-header"></li>

                <li class="sidebar-item">
                    <router-link class="sidebar-link" to="/" exact-active-class="active">
                        <i class="align-middle me-2 fas fa-fw fa-home"></i>
                        <span class="align-middle">Accueil</span>
                    </router-link>
                </li>

                <li class="sidebar-item">
                    <router-link class="sidebar-link" to="/imeubles" exact-active-class="active">
                        <i class="align-middle me-2 fas fa-fw fa-building"></i>
                        <span class="align-middle">Immeubles</span>
                    </router-link>
                </li>

                <li class="sidebar-item">
                    <router-link class="sidebar-link" to="/appartments" exact-active-class="active">
                        <i class="align-middle me-2 fas fa-fw fa-building"></i>
                        <span class="align-middle">Appartements</span>
                    </router-link>
                </li>

                <li class="sidebar-item">
                    <router-link class="sidebar-link" to="/contracts" exact-active-class="active">
                        <i class="align-middle me-2 fas fa-fw fa-file-alt"></i>
                        <span class="align-middle">Contrats En Cours</span>
                    </router-link>
                </li>

                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'Contracts.expire' }" exact-active-class="active">
                        <i class="align-middle me-2 fas fa-fw fa-file-alt"></i>
                        <span class="align-middle">Contrats expirés</span>
                    </router-link>
                </li>

                <li class="sidebar-item">
                    <router-link class="sidebar-link" :to="{ name: 'Bank' }" exact-active-class="active">
                        <i class="align-middle me-2 fas fa-fw fa-file-alt"></i>
                        <span class="align-middle">Comptes bancaires</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'SideBar',
    data() {
        return {
            userName: null,
            lastname: null,
        };
    },

    mounted() {
        if (localStorage.getItem("user") != "undefined") {
            const user = JSON.parse(localStorage.getItem("user")) ?? "";
            if (user) {
                this.userName = user.firstname;
                this.lastname = user.lastname;
            }
        }
    }
}
</script>

<style scoped>
.sidebar-item .router-link-active {
    /* Applique ici le style pour les liens actifs */
    font-weight: bold;
    color: #007bff; /* Couleur pour l'élément actif */
}
</style>
