<template>
    <div class="wrapper">
        <Sidebar />
        <div class="main">
            <HorizBarre />
            <main class="content">
                <div class="container-fluid">
                    <div class="header mb-4">
                        <h1 class="header-title">
                            Ajouter Un Contrat
                        </h1>
                    </div>

                    <!-- Vérifiez si les données de l'appartement sont prêtes -->
                    <div v-if="appartment.data">
                        <div class="card">
                            <div class="card-body">
                                <form @submit.prevent="saveContract">
                                    <div class="row">
                                        <!-- Partie 1 : Informations du client -->
                                        <div class="col-md-6">

                                            <h4 class="mb-4 text-primary">Informations de L'appartement</h4>
                                            <div class="p-4 rounded border shadow-sm bg-light mb-4">

                                                <h3>Appartement {{ appartment.data.appartname }} de L'imeuble {{
                                                    appartment.data.building.name }}</h3>
                                                <h4><strong>Adresse:</strong> {{ appartment.data.building.address ||
                                                    'Aucune adresse fournie' }}</h4>
                                                <h4>
                                                    <strong>Localisation:</strong> {{ appartment.data.building.city
                                                    }} ( {{
                                                        appartment.data.building.district }} )
                                                </h4>

                                            </div>


                                            <h4 class="mb-4 text-primary">Informations du client</h4>
                                            <div class="p-4 rounded border shadow-sm bg-light">
                                                <!-- Prénom et Nom sur la même ligne -->
                                                <div class="row mb-3">
                                                    <div class="col-md-6">
                                                        <label for="firstname"
                                                            class="form-label col-form-label fw-bold text-primary mb-2">Prénom</label>
                                                        <input type="text" id="firstname" v-model="contract.firstname"
                                                            class="form-control border-primary" required>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="lastname"
                                                            class="form-label col-form-label fw-bold text-primary mb-2">Nom</label>
                                                        <input type="text" id="lastname" v-model="contract.lastname"
                                                            class="form-control border-primary" required>
                                                    </div>
                                                </div>

                                                <!-- Email -->
                                                <div class="mb-3">
                                                    <label for="email"
                                                        class="form-label col-form-label fw-bold text-primary mb-2">Email</label>
                                                    <input type="email" id="email" v-model="contract.email"
                                                        class="form-control border-primary" required>
                                                </div>

                                                <!-- Téléphone et Date de début sur la même ligne -->
                                                <div class="row mb-3">
                                                    <div class="col-md-6">
                                                        <label for="phonenumber"
                                                            class="form-label col-form-label fw-bold text-primary mb-2">Téléphone</label>
                                                        <input type="text" id="phonenumber"
                                                            v-model="contract.phonenumber"
                                                            class="form-control border-primary" required>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label for="started_at"
                                                            class="form-label col-form-label fw-bold text-primary mb-2">Date
                                                            de début</label>
                                                        <input type="date" id="started_at" v-model="contract.started_at"
                                                            class="form-control border-primary" required>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Partie 2 : Éléments du contrat -->
                                        <div class="col-md-6">
                                            <h4 class="mb-4 text-primary">Éléments du contrat</h4>
                                            <div v-for="item in rentalItems" :key="item.id"
                                                class="mb-4 p-3 rounded border shadow-sm bg-light">
                                                <div class="row align-items-center">
                                                    <!-- Nom de l'élément -->
                                                    <label :for="'item-' + item.id"
                                                        class="col-form-label fw-bold text-primary mb-2">{{ item.name
                                                        }}</label>

                                                    <!-- Input pour le montant -->
                                                    <div class="col-6 mb-2">
                                                        <input type="number" :id="'item-' + item.id"
                                                            v-model="contract.items[item.id].amount"
                                                            class="form-control border-primary"
                                                            placeholder="Saisir un montant" min="0"
                                                            style="background-color: #f9f9ff;">
                                                    </div>

                                                    <!-- Sélection de la fréquence -->
                                                    <div class="col-6">
                                                        <select :id="'frequency-' + item.id"
                                                            v-model="contract.items[item.id].frequency"
                                                            class="form-select border-primary"
                                                            style="background-color: #f9f9ff;">
                                                            <option disabled value="">Choisir une fréquence</option>
                                                            <option value="MONTHLY_POSTPAID">MENSUEL POSTPAYÉ</option>
                                                            <option value="MONTHLY_PREPAID">MENSUEL PRÉPAYÉ</option>
                                                            <option value="ONCE">Une seule fois</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" class="btn btn-success mt-4">Enregistrer le contrat</button>
                                </form>
                            </div>

                        </div>

                        <div v-if="showSuccessToast" class="custom-toast">
                            Contrat ajouté avec succès !
                        </div>
                    </div>

                    <!-- Indicateur de chargement si les données de l'appartement ne sont pas encore chargées -->
                    <div v-else class="loading-container">
                        <div class="spinner"></div>
                        <p>Chargement des données de l'appartement...</p>
                    </div>
                </div>
            </main>

            <FooterPage />
        </div>
    </div>
</template>


<script>
import Sidebar from '../../components/Sidebar.vue'
import FooterPage from '../../components/Footer.vue'
import HorizBarre from '../../components/HorizBarre.vue'
import fetch from '../../utils/fetch';

export default {
    name: "ContractAdd",
    components: {
        Sidebar,
        FooterPage,
        HorizBarre
    },
    data() {
        return {
            appartment: {
                data: null,
                current: null,
                isUpdate: false,
                isLoading: true,
                isError: false,
                errorMessage: "",
            },
            contract: {
                firstname: "",
                lastname: "",
                email: "",
                phonenumber: "",
                started_at: "",
                appartment_id: this.$route.params.id,
                items: {}
            },
            rentalItems: [],
            showModal: false,
            showSuccessToast: false,
        };
    },

    mounted() {
        this.getAppartement(this.$route.params.id);
        this.getRentalItems();
    },
    methods: {
        showSuccessNotification() {
            this.showSuccessToast = true;
            setTimeout(() => this.showSuccessToast = false, 3000);
        },

        getAppartement(id) {
            this.appartment.isLoading = true;
            fetch({ url: `appartments/${id}` })
                .then(({ data }) => {
                    this.appartment.data = data;
                    this.appartment.isError = false;
                })
                .catch(() => {
                    this.appartment.isError = true;
                    this.appartment.errorMessage = "Une erreur est survenue, réessayez";
                })
                .finally(() => {
                    this.appartment.isLoading = false;
                });
        },

        getRentalItems() {
            fetch({ url: 'rental-items' })
                .then(({ data }) => {
                    this.rentalItems = data;

                    // Initialiser contract.items pour chaque item
                    data.forEach(item => {
                        this.contract.items[item.id] = {
                            amount: null,
                            frequency: 'MONTHLY_POSTPAID' // Valeur par défaut
                        };
                    });
                })
                .catch(error => {
                    console.error("Erreur lors du chargement des éléments de location :", error);
                });
        },

        saveContract() {
            // Prépare les données du contrat avec les éléments de location sélectionnés
            const contractData = {
                ...this.contract,
                items: Object.entries(this.contract.items)
                    .filter(([_, { amount }]) => amount) // Filtre les éléments sans montant
                    .map(([item_id, { amount, frequency }]) => ({
                        item_id,
                        amount,
                        frequency
                    }))
            };

            fetch({ url: `contracts`, method: 'POST', data: contractData })
                .then(response => {
                    this.showSuccessNotification();
                    this.$router.push({ name: 'Appartments' });
                })
                .catch(error => {
                    console.error(error);
                    alert("Une erreur est survenue.");
                });
        }
    }

}
</script>


<style scoped>
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    font-size: 18px;
    color: #555;
}

.spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}


.custom-toast {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #28a745;
    /* Couleur de fond verte */
    color: white;
    /* Couleur du texte */
    padding: 15px 30px;
    /* Espacement interne */
    border-radius: 8px;
    /* Coins arrondis */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    /* Ombre portée */
    animation: fadeOut 3s forwards;
    /* Animation de disparition */
    font-size: 1.1em;
    /* Taille de police */
    text-align: center;
    /* Alignement du texte */
    max-width: 80%;
    /* Largeur maximale */
    width: 300px;
    /* Largeur fixe */
    z-index: 9999;
    /* S'assurer qu'il est au-dessus des autres éléments */
    transition: transform 0.3s ease;
    /* Transition pour l'apparition */
    transform: translate(-50%, -50%) scale(1.05);
    /* Légère échelle pour l'effet */
}

/* Effet de sortie */
@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.8);
    }
}

/* Ajouter un effet au survol */
.custom-toast:hover {
    cursor: pointer;
    background-color: #218838;
    /* Couleur légèrement plus foncée au survol */
}
</style>