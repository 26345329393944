import axios from "axios";
import fetch, { API_URL } from "@/utils/fetch";
import router from "@/router";
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,

  state: {
    authuser: {
      user: {},
      // user: null,
      token: null,
    },
    isLoading: true,
    isSuccess: true,
    showMessage: false,
    message: "",
    checkIsLoading: false,

  },

  getters: {
    authenticated(state) {
      return state.authuser && state.authuser.token && state.authuser.user;
    },

    user(state) {
      return state.authuser.user;
    },
    getField
  },

  mutations: {
    updateField,
    SET_TOKEN(state, token) {
      state.authuser.token = token;
    },

    SET_USER(state, user) {
      state.authuser.user = user;
    },
    IS_LOADING(state) {
      state.isLoading = true
    },
    NOT_LOADING(state) {
      state.isLoading = false
    },
    IS_SUCCESS(state) {
      state.isSuccess = true
    },
    IS_ERROR(state) {
      state.isSuccess = false
    },
    SET_MESSAGE(state, message) {
      state.message = message
    },
    SHOW_MESSAGE(state,) {
      state.showMessage = true
    },
    HIDE_CHECK(state) {
      state.checkIsLoading = false
    },
    SHOW_CHECK(state) {
      state.checkIsLoading = true
    },
    HIDE_MESSAGE(state) {
      state.showMessage = false
    },
  },

  actions: {
    //  async register(user){


    async login({ commit }, data) {
      commit("IS_LOADING")
      commit("HIDE_MESSAGE")

      fetch({ method: "POST", url: "login", data, isAuth: false }).then(({ data }) => {
        commit("SET_TOKEN", data.token);
        commit("SET_USER", data.data);
        commit("IS_SUCCESS");
        localStorage.setItem("token", data.token);
        localStorage.setItem("user", JSON.stringify(data.data));
        router.replace({ name: "Home" })
      }).catch(() => {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
        commit("SET_MESSAGE", "Email ou Mot de passe incorrect");
        commit("IS_ERROR");
        localStorage.clear()
      }).finally(() => {
        commit("NOT_LOADING");
        commit("SHOW_MESSAGE");

      })


    },


    async logout({ commit }) {
      commit("IS_LOADING")
      commit("HIDE_MESSAGE")

      fetch({ method: "POST", url: "logout", data, isAuth: true }).then(({ data }) => {
        commit("IS_SUCCESS");
        commit("SET_MESSAGE", "Vous etes deconnecte");

      }).catch((err) => {
        commit("IS_ERROR")
        commit("SET_MESSAGE", `Error - ${err}`);

      }).finally(() => {
        localStorage.clear()
        commit("NOT_LOADING");
        commit("SHOW_MESSAGE");
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
        router.replace({ name: "Login" })
      })
    },

    // async checkToken({ commit }) {
    //   commit("SHOW_CHECK")

    //   fetch({ method: "GET", url: "user", data: null, isAuth: true }).then(({ data }) => {

    //     commit("SET_TOKEN", localStorage.getItem("token"));
    //     commit("SET_USER", data.data);
    //     commit("IS_SUCCESS");
    //     localStorage.setItem("user", JSON.stringify(data.data));
    //   }).catch(() => {
    //     commit("SET_TOKEN", null);
    //     commit("SET_USER", null);
    //     localStorage.clear()
    //     router.replace({ name: "Login" })
    //   }).finally(() => {
    //     commit("NOT_LOADING");
    //     commit("SHOW_MESSAGE");
    //     commit("HIDE_CHECK")


    //   })
    // },


    async checkToken({ commit }) {
      commit("SHOW_CHECK");

      const currentRoute = router.currentRoute;

      try {
        const { data } = await fetch({ method: "GET", url: "user", data: null, isAuth: true });

        commit("SET_TOKEN", localStorage.getItem("token"));
        commit("SET_USER", data.data);
        commit("IS_SUCCESS");
        localStorage.setItem("user", JSON.stringify(data.data));
      } catch {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
        localStorage.clear();

        const routeName = currentRoute.name || currentRoute.value?.name;
        if (routeName === "Verification.show") {
          return;
        }

        router.replace({ name: "Login" });
      } finally {
        commit("NOT_LOADING");
        commit("SHOW_MESSAGE");
        commit("HIDE_CHECK");
      }
    }

  },
};
