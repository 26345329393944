<template>
    <div class="wrapper">
        <Sidebar />
        <div class="main">
            <HorizBarre />

            <main class="content">
                <div class="container-fluid">
                    <div class="header">
                        <h1 class="header-title">Liste des contrats en cours</h1>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between align-items-center">
                                    <div>
                                        <h5 class="card-title">Liste des contrats en cours</h5>
                                        <h6 class="card-subtitle text-muted">
                                            Une vue d'ensemble de tous les contrats en cours
                                        </h6>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div v-if="contracts.isLoading" class="loading-container">
                                        <div class="spinner"></div>
                                        <p>Chargement en cours...</p>
                                    </div>
                                    <div v-else>
                                        <div v-if="contracts.isError" class="error-message">
                                            {{ contracts.errorMessage }}
                                        </div>
                                        <table id="datatables-responsive" class="table table-striped" style="width:100%"
                                            v-if="contracts.data">
                                            <thead>
                                                <tr>
                                                    <th><h4>Utilisateur</h4></th>
                                                    <th><h4>Appartement</h4></th>
                                                    <th><h4>Solde</h4></th>
                                                    <th><h4>Status</h4></th>
                                                    <th><h3>Date de Début</h3></th>
                                                    <th><h4>Actions</h4></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(contract) in contracts.data" :key="contract.id">
                                                   
                                                    <td>
                                                        <h4>{{ contract.user.firstname }} {{ contract.user.lastname }}
                                                        </h4>
                                                    </td>
                                                    <td>
                                                        <h4>{{ contract.appartment.building.name }} - {{
                                                            contract.appartment.appartname }}</h4>
                                                    </td>

                                                    <td>
                                                        <h4>
                                                            <span
                                                                :class="{ 'text-success': contract.current_solde >= 0, 'text-danger': contract.current_solde < 0 }">
                                                                {{ contract.current_solde }} FCFA
                                                            </span>
                                                        </h4>
                                                    </td>
                                                    <td>
                                                        <h4
                                                            :class="{ 'text-primary': contract.status == 'IN_PROGRESS', 'text-secondary': contract.status == 'TERMINATED', 'text-success': contract.status == 'COMPLETED' }">
                                                            {{ contract.status === 'IN_PROGRESS' ? 'EN COURS' :
                                                            contract.status === 'TERMINATED' ? "TERMINÉ" : "COMPLÉTÉ"}}
                                                        </h4>

                                                    </td>
                                                    <td>
                                                        <h5>{{ new Date(contract.started_at).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' }) }}
                                                        </h5>
                                                    </td>
                                                    <td>
                                                        <button class="action-button view-button">Voir</button>
                                                        <button class="action-button edit-button">Modifier</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <FooterPage />
        </div>
    </div>
</template>

<script>
import Sidebar from '../../components/Sidebar.vue'
import FooterPage from '../../components/Footer.vue'
import HorizBarre from '../../components/HorizBarre.vue'
import fetch from '../../utils/fetch';

export default {
    name: "ContractPage",
    components: {
        Sidebar,
        FooterPage,
        HorizBarre
    },
    data() {
        return {
            user: null,
            contracts: {
                data: null,
                current: null,
                isUpdate: false,
                isLoading: true,
                isError: false,
                errorMessage: "",
            },
        };
    },
    mounted() {
        this.getContract().then(() => {
            $("#datatables-responsive").DataTable({
                responsive: true,
                pageLength: 100,
            });
        });
    },
    methods: {
        getContract() {
            this.contracts.isLoading = true;
            return fetch({ url: `contracts` })
                .then(({ data }) => {
                    this.contracts.data = data.data ?? data;
                    this.contracts.isError = false;
                })
                .catch(() => {
                    this.contracts.isError = true;
                    this.contracts.errorMessage = "Une erreur est survenue, réessayez";
                    this.contracts.data = null;
                })
                .finally(() => {
                    this.contracts.isLoading = false;
                });
        },
    }
};
</script>

<style>
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    text-align: center;
    font-size: 18px;
    color: #555;
}

.spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.contracts-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    margin-top: 15px;
}

.contracts-table th,
.contracts-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.contracts-table th {
    background-color: #f8f9fa;
    font-weight: bold;
}

.status-active {
    color: green;
    font-weight: bold;
}

.status-inactive {
    color: red;
    font-weight: bold;
}

.action-button {
    padding: 8px 12px;
    margin-right: 5px;
    border: none;
    color: white;
    cursor: pointer;
}

.view-button {
    background-color: #3498db;
}

.edit-button {
    background-color: #f39c12;
}

.error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
}
</style>
