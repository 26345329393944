import router from "@/router";
import axios from "axios";
import { toHandlers } from "vue";

export const API_URL = process.env.VUE_APP_API_TRACKER_BASE_URL ?? 'https://www.ultrabail.net/backend/api/';
// export const API_URL = process.env.VUE_APP_API_TRACKER_BASE_URL ?? 'http://127.0.0.1:8000/api/';


const instance = axios.create({
    baseURL: API_URL
});

// Alter defaults after instance has been created

export default function ({method='GET',url="",data=[],isAuth= true}) {
    let token = localStorage.getItem("token") 
    if(isAuth && token != null){
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        instance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    }

   return  new Promise((resolve, reject) => {
                instance({
        method,
        url,
        data
    }).then((res)=>{
       
        return resolve(res); 
      
        // return res;

    }).catch((err)=>{

        if (err.response?.status == 401){
            localStorage.clear();
            // router.push({name:"Login"})
            // toast.success("Votre token n'est pas valide")
        }
        reject(err)
        // throw err

    })
      });
  
    

   
}
