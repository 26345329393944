<template>
    <div class="verification-page">
        <div>
            <div v-if="facture.isLoading" class="loading-container">
                <div class="spinner"></div>
                <p>Chargement en cours...</p>
            </div>

            <div v-if="facture.isError" class="error-container">
                <p>{{ facture.errorMessage }}</p>
            </div>

            <div v-if="facture.data && !facture.isLoading" class="pdf-container">
                <iframe :src="pdfUrl" class="pdf-viewer" frameborder="0"></iframe>
                <br />
                <a :href="pdfUrl" download class="btn btn-download">
                    Télécharger la facture
                </a>
            </div>
        </div>
    </div>
</template>


<script>
import fetch from "../../utils/fetch";

export default {
    name: "VerificationPage",
    data() {
        return {
            facture: {
                data: null,
                current: null,
                isUpdate: false,
                isLoading: true,
                isError: false,
                errorMessage: "",
            },
        };
    },
    computed: {
        pdfUrl() {
            // return this.facture.data ? `${process.env.VUE_APP_API_URL}/${this.facture.data.pdf_link}` : "";
            // return this.facture.data ? `http://127.0.0.1:8000/${this.facture.data.pdf_link}` : "";
            return this.facture.data ? `https://www.ultrabail.net/backend/${this.facture.data.pdf_link}` : "";

        },
    },
    mounted() {
        this.getFacture(this.$route.params.id);
    },
    methods: {
        getFacture(id) {
            this.facture.isLoading = true;
            fetch({ url: `verification-facture/${id}` })
                .then(({ data }) => {
                    this.facture.data = data;
                    console.log(data);
                    this.facture.isError = false;
                })
                .catch(() => {
                    this.facture.isError = true;
                    this.facture.errorMessage = "Une erreur est survenue, réessayez";
                })
                .finally(() => {
                    this.facture.isLoading = false;
                });
        },
    },
};
</script>

<style scoped>
/* Conteneur principal avec fond et ombrage */
.verification-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

/* Chargement et erreur */
.loading-container, .error-container {
    text-align: center;
    padding: 20px;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Conteneur du PDF */
.pdf-container {
    text-align: center;
    margin-top: 20px;
    color: #333;
}

.pdf-container h3 {
    color: #333;
    font-size: 1.4em;
    font-weight: 600;
}

/* Visionneuse PDF */
.pdf-viewer {
    width: 100%;
    height: 80vh;
    border: 1px solid #ddd;
    border-radius: 6px;
    margin-top: 10px;
}

/* Bouton de téléchargement */
.btn-download {
    display: inline-block;
    margin-top: 15px;
    padding: 12px 24px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 6px;
    font-size: 1em;
    font-weight: bold;
    transition: background-color 0.3s;
}

.btn-download:hover {
    background-color: #0056b3;
}

/* Transition pour le bouton */
.error-container p {
    color: #e74c3c;
    font-weight: bold;
}
</style>