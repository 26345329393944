<template>
    <div class="wrapper">
        <Sidebar />
        <div class="main">
            <HorizBarre />
            <main class="content">
                <div class="container-fluid">
                    <div class="header">
                        <h1 class="header-title">
                            Liste des Immeubles
                        </h1>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header d-flex justify-content-between align-items-center">

                                    <div class="filter">
                                        <label for="filter-country" class="me-2">Filtrer par Pays :</label>
                                        <select id="filter-country" v-model="selectedCountry" @change="applyFilters">
                                            <option value="">Tous les pays</option>
                                            <option v-for="country in uniqueCountries" :key="country" :value="country">
                                                {{ country }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="filter">
                                        <label for="filter-city" class="me-2">Filtrer par Ville :</label>
                                        <select id="filter-city" v-model="selectedCity" @change="applyFilters">
                                            <option value="">Toutes les villes</option>
                                            <option v-for="city in uniqueCities" :key="city" :value="city">
                                                {{ city }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="filter">
                                        <label for="filter-district" class="me-2">Filtrer par Quartier :</label>
                                        <select id="filter-district" v-model="selectedDistrict" @change="applyFilters">
                                            <option value="">Tous les quartiers</option>
                                            <option v-for="district in uniqueDistricts" :key="district"
                                                :value="district">
                                                {{ district }}
                                            </option>
                                        </select>
                                    </div>


                                </div>

                                <div class="card-body">
                                    <div v-if="imeubles.isLoading" class="loading-container">
                                        <div class="spinner"></div>
                                        <p>Chargement en cours...</p>
                                    </div>

                                    <table v-else id="datatables-responsive" class="table table-striped"
                                        style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Code</th>
                                                <th>Appartement</th>
                                                <th>Banque</th>
                                                <th>Adresse</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="imeuble in filteredImeubles" :key="imeuble.id">
                                                <td><h4>{{ imeuble.name }}</h4></td>
                                                <td><h4>{{ imeuble.buildingcode }}</h4></td>
                                                <td>
                                                    <h4>
                                                        <p><span class="fw-bold fs-5">Occupé : </span><span class="badge bg-success fs-5 p-2">{{
                                                        imeuble.appartments_with_contract }}</span></p>
                                                        <p>
                                                        <span class="fw-bold fs-5">Vacant : </span>
                                                        <span class="badge bg-warning text-dark fs-5 p-2">{{
                                                        imeuble.appartments_without_contract }}</span>
                                                        </p>
                                                    </h4>
                                                </td>
                                                <td><h4>{{ imeuble.bank ? imeuble.bank.name : "" }}</h4></td>


                                                <td><h4>{{ imeuble.address ?? "" }} {{ imeuble.country }} ( {{ imeuble.city }} , {{
                                                    imeuble.district }})</h4></td>
                                                <td>
                                                    <button class="action-button view-button">Voir</button>
                                                    <!-- <button class="action-button edit-button">Modifier</button> -->
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </main>

            <FooterPage />
        </div>
    </div>
</template>

<script>
import Sidebar from '../../components/Sidebar.vue'
import FooterPage from '../../components/Footer.vue'
import HorizBarre from '../../components/HorizBarre.vue'
import fetch from '../../utils/fetch';

export default {
    name: "HomePage",
    components: {
        Sidebar,
        FooterPage,
        HorizBarre
    },
    data() {
        return {
            user: null,
            imeubles: {
                data: null,
                isLoading: true,
                isError: false,
                errorMessage: "",
            },
            selectedCity: "",
            selectedDistrict: "",
            selectedCountry: "",
        };
    },
    computed: {
        uniqueCities() {
            if (!this.imeubles.data) return [];
            return Array.from(new Set(this.imeubles.data.map(imeuble => imeuble.city)));
        },
        uniqueDistricts() {
            if (!this.imeubles.data) return [];
            return Array.from(new Set(this.imeubles.data.map(imeuble => imeuble.district)));
        },
        uniqueCountries() {
            if (!this.imeubles.data) return [];
            return Array.from(new Set(this.imeubles.data.map(imeuble => imeuble.country)));
        },
        filteredImeubles() {
            return this.imeubles.data.filter(imeuble => {
                return (!this.selectedCity || imeuble.city === this.selectedCity) &&
                    (!this.selectedDistrict || imeuble.district === this.selectedDistrict) &&
                    (!this.selectedCountry || imeuble.country === this.selectedCountry);
            });
        }
    },
    mounted() {
        this.getImeubles().then(() => {
            $("#datatables-responsive").DataTable({
                responsive: true,
                pageLength: 50,
            });
        });
    },
    beforeUnmount() {
        $("#datatables-responsive").DataTable().destroy();
    },
    methods: {
        getImeubles() {
            this.imeubles.isLoading = true;
            return fetch({ url: `building` }).then(({ data }) => {
                this.imeubles.data = data.data ?? data;
                this.imeubles.isError = false;
            }).catch(() => {
                this.imeubles.isError = true;
                this.imeubles.errorMessage = "Une erreur est survenue, réessayez";
                this.imeubles.data = null;
            }).finally(() => {
                this.imeubles.isLoading = false;
            });
        },
        applyFilters() {
            // Détruire et recréer le tableau pour appliquer le filtre
            $('#datatables-responsive').DataTable().destroy();
            this.$nextTick(() => {
                $("#datatables-responsive").DataTable({
                    responsive: true,
                    pageLength: 50,
                });
            });
        },
        editImeuble(id) {
            // Logique pour éditer l'immeuble
        },
        deleteImeuble(id) {
            // Logique pour supprimer l'immeuble
        },
    }
}
</script>

<style>
.success {
    color: green;
}

.warning {
    color: red;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    font-size: 18px;
    color: #555;
}

.spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }

    
}

.action-button {
    padding: 8px 12px;
    margin-right: 5px;
    border: none;
    color: white;
    cursor: pointer;
}
.view-button { background-color: #3498db; }
.edit-button { background-color: #f39c12; }
</style>