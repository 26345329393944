
import fetch from "@/utils/fetch";
import router from "@/router";
import { getField, updateField } from "vuex-map-fields";

export default {
  namespaced: true,

  state: {
    user:null,
    isLoading: false,
    isSuccess: false,
    showMessage: false,
    message: "",

  },

  getters: {
   
    getField
  },

  mutations: {
    updateField,
   

    SET_USER(state, user) {
      state.user = user;
    },
    IS_LOADING(state) {
      state.isLoading = true
    },
    NOT_LOADING(state) {
      state.isLoading = false
    },
    IS_SUCCESS(state) {
      state.isSuccess = true
    },
    IS_ERROR(state) {
      state.isSuccess = false
    },
    SET_MESSAGE(state, message) {
      state.message = message
    },
    SHOW_MESSAGE(state,) {
      state.showMessage = true
    },
    HIDE_MESSAGE(state,) {
      state.showMessage = false
    },
  },

  actions: {
    //  async register(user){


    async handleRegister({ commit }, data) {
      commit("IS_LOADING")
      commit("HIDE_MESSAGE")

      fetch({ method: "POST", url: "login", data, isAuth: false }).then(({ data }) => {
    
        commit("SET_USER", data.data);
        commit("IS_SUCCESS");
        router.replace({ name: "Login" })
      }).catch(() => {
        commit("SET_USER", null);
        commit("SET_MESSAGE", "Information incorrecte");
        commit("IS_ERROR");
        localStorage.clear()
      }).finally(() => {
        commit("NOT_LOADING");
        commit("SHOW_MESSAGE");

      })


    },


  
  },
};
