<template>
    <div class="wrapper">
        <Sidebar />
        <div class="main">
            <HorizBarre />

            <main class="content">
                <div class="container-fluid">
                    <div class="header mb-4">
                        <h1 class="header-title">Informations Sur le compte bancaire</h1>
                    </div>

                    <!-- Vérifie si bankAccount et bankAccount.data existent -->
                    <div v-if="bankAccount && bankAccount.data">
                        <div class="row">
                            <div class="col-md-12 mb-3">
                                <div class="card shadow-sm">
                                    <div class="card-header bg-primary text-white">
                                        Informations sur le compte bancaire
                                    </div>
                                    <div class="card-body">
                                        <h3>Nom de la banque: {{ bankAccount.data.name }}</h3>
                                        <h4><strong>Adresse:</strong> {{ bankAccount.data.description || 'Aucune description fournie' }}</h4>
                                        <h4>Solde:
                                            <span
                                                :class="{ 'text-success': bankAccount.data.current_solde >= 0, 'text-danger': bankAccount.data.current_solde < 0 }">
                                                {{ bankAccount.data.current_solde }} FCFA
                                            </span>
                                        </h4>

                                        <div v-if="bankAccount.data">
                                            <div class="d-flex gap-2">
                                                <button @click="showModal = true" class="btn btn-success">
                                                    Ajouter une dépense
                                                </button>
                                                <button @click="showModalAdd = true" class="btn btn-info">
                                                    Ajouter une entrée
                                                </button>
                                            </div>


                                            <div v-if="showModal" tabindex="-1" z-index="999" @click.self="showModal = false" class="modal" id="centeredModalSuccess" role="dialog" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title">Ajouter une dépense</h5>
                                                            <button type="button" class="btn-close" @click="showModal = false" aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body m-3">
                                                            <h4 class="card-header mb-3">Ajouter La dépense</h4>
                                                            
                                                            <div class="mb-3">
                                                                <label for="amount" class="form-label">Montant</label>
                                                                <input type="number" @keypress="isNumber($event)" v-model="amount" id="amount" class="form-control" placeholder="Entrez le montant">
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="description" class="form-label">Description</label>
                                                                <textarea v-model="description" id="description" class="form-control" rows="3" placeholder="Entrez la description"></textarea>
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="paymentImage" class="form-label">Ajouter une image</label>
                                                                <input type="file" @change="handleImageUpload" id="paymentImage" class="form-control" accept="image/*">
                                                            </div>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" @click="showModal = false" class="btn btn-secondary">Fermer</button>
                                                            <button type="button" @click="saveDepense()" class="btn btn-success">Enregistrer</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="showModalAdd" tabindex="-1" z-index="999" @click.self="showModalAdd = false" class="modal" id="centeredModalSuccess" role="dialog" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title">Ajouter une Entrée</h5>
                                                            <button type="button" class="btn-close" @click="showModalAdd = false" aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body m-3">
                                                            <h4 class="card-header mb-3">Ajouter L'entrée</h4>
                                                            
                                                            <div class="mb-3">
                                                                <label for="amount" class="form-label">Montant</label>
                                                                <input type="number" @keypress="isNumber($event)" v-model="amount" id="amount" class="form-control" placeholder="Entrez le montant">
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="description" class="form-label">Description</label>
                                                                <textarea v-model="description" id="description" class="form-control" rows="3" placeholder="Entrez la description"></textarea>
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="paymentImage" class="form-label">Ajouter une image</label>
                                                                <input type="file" @change="handleImageUpload" id="paymentImage" class="form-control" accept="image/*">
                                                            </div>
                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" @click="showModalAdd = false" class="btn btn-secondary">Fermer</button>
                                                            <button type="button" @click="saveEntree()" class="btn btn-success">Enregistrer</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="showSuccessToast" class="custom-toast">
                                                <strong>Succès !</strong> Action enregistrés avec succès !
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                      
                        <div v-if="bankAccount.data.transactions" class="row">
                            <div class="col-12 mb-3">
                                <div class="card shadow-sm">
                                    <div
                                        class="card-header bg-primary text-white d-flex justify-content-between align-items-center">
                                        <span> Informations sur les transactions de cette banque</span>
                                        <button class="btn btn-light btn-sm" @click="toggleEvents">
                                            {{ eventsExpanded ? 'Réduire' : 'Voir plus' }}
                                        </button>
                                    </div>

                                    <div class="card-body" v-show="eventsExpanded">
                                        <div v-if="bankAccount.data.transactions">
                                            <!-- <h5>Événements du contrat</h5> -->
                                            <ul class="list-unstyled">
                                                <li v-for="event in paginatedEvents" :key="event.id" class="mb-3">
                                                    <strong>Type: </strong>
                                                    <span :class="getTypeClass(event.transaction_type)">
                                                        {{ translateEventType(event.transaction_type) }}
                                                    </span> 
                                                   <span> - 
                                                    <strong>Montant: </strong>
                                                        <span class="text-primary">{{ event.amount }} FCFA</span>
                                                   </span>
                                                    
                                                    <br>
                                                    <strong>Description: </strong> {{ event.description || 'Aucune note' }}
                                                    <br>
                                                    <small><strong>Date: </strong> {{ event.created_at }}</small>

                                                    <template v-if="event.image">
                                                        <br>
                                                        <img :src="`https://www.ultrabail.net/backend/${event.image}`" alt="Image associée" class="mt-2" style="max-width: 100px; max-height: 100px;">
                                                    </template>
                                                    <br>        
                                                    <button class="btn btn-warning btn-sm mt-2" @click="openEditModal(event)">Modifier</button>
                                                </li>
                                            </ul>


                                            <div v-if="showEditModal" tabindex="-1" z-index="999" @click.self="showEditModal = false" class="modal" role="dialog" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title">Modifier l'événement</h5>
                                                            <button type="button" class="btn-close" @click="showEditModal = false" aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body m-3">
                                                            <h4 class="card-header mb-3">Modifiez les informations de l'événement</h4>


                                                         

                                                            <div class="mb-3">
                                                                <label for="eventAmount" class="form-label">Montant</label>
                                                                <input type="number" id="eventAmount" v-model="editingEvent.amount" class="form-control" placeholder="Entrez le montant">
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="eventNote" class="form-label">Description</label>
                                                                <textarea id="eventNote" v-model="editingEvent.description" class="form-control" rows="3" placeholder="Ajouter une Description"></textarea>
                                                            </div>

                                                            <div class="mb-3">
                                                                <label for="eventImage" class="form-label">Modifier l'image</label>
                                                                <input type="file" @change="handleEditImageUpload" id="eventImage" class="form-control" accept="image/*">
                                                            </div>
                                                        </div>

                                                        <div class="modal-footer">
                                                            <button type="button" @click="showEditModal = false" class="btn btn-secondary">Fermer</button>
                                                            <button type="button" @click="updateEvent()" class="btn btn-success">Enregistrer les modifications</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div v-if="showUpdateSuccessToast" class="custom-toast">
                                                <strong>Succès !</strong> transaction modifié avec succès !
                                            </div>

                                            <!-- Pagination Controls -->
                                            <nav aria-label="Page navigation">
                                                <ul class="pagination justify-content-center">
                                                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                                        <button class="page-link" @click="prevPage">Précédent</button>
                                                    </li>
                                                    <li class="page-item" v-for="page in totalPages" :key="page"
                                                        :class="{ active: currentPage === page }">
                                                        <button class="page-link" @click="goToPage(page)">{{ page
                                                            }}</button>
                                                    </li>
                                                    <li class="page-item"
                                                        :class="{ disabled: currentPage === totalPages }">
                                                        <button class="page-link" @click="nextPage">Suivant</button>
                                                    </li>
                                                </ul>
                                            </nav>

                                        </div>
                                        <div v-else>
                                            <p class="text-center mt-3">Aucune transaction pour cette banque.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <p>Chargement des informations de l'appartement...</p>
                    </div>
                </div>
            </main>

            <FooterPage />
        </div>
    </div>
</template>



<script>

import Sidebar from '../../components/Sidebar.vue'
import FooterPage from '../../components/Footer.vue'
import HorizBarre from '../../components/HorizBarre.vue'
import fetch from '../../utils/fetch';

export default {
    name: "AppartmentShow",
    components: {
        Sidebar,
        FooterPage,
        HorizBarre
    },
    data() {
        return {
            bankAccount: {
                data: null,
                current: null,
                isUpdate: false,
                isLoading: true,
                isError: false,
                errorMessage: "",
            },
            showContractInfo: false,
            eventsExpanded: false,
            showModal: false,
            showModalAdd: false,
            showNoteModal: false,
            paymentItems: [],
            note: '',
            paymentImage: null,
            showSuccessToast: false,
            showSuccessNoteToast: false,
            showUpdateSuccessToast: false,
            currentPage: 1,
            itemsPerPage: 5,
            amount: null,
            description: '',
            image: null,
            showEditModal: false,
            editingEvent: {
                id: '',
                description: '',
                amount: '',
                image: null
            },

        };
    },


    computed: {
        totalPages() {
            if (this.bankAccount.data.transactions) {
                return Math.ceil(this.bankAccount.data.transactions.length / this.itemsPerPage);
            }
            return 0;
        },
        paginatedEvents() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            return this.bankAccount.data.transactions.slice(start, start + this.itemsPerPage);
        },
    },
    mounted() {
        this.getBankAccount(this.$route.params.id);

    },
    methods: {

        handleImageUpload(event) {
            this.image = event.target.files[0];
        },
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        goToPage(page) {
            this.currentPage = page;
        },
        showSuccessNotification() {
            this.showSuccessToast = true;
            setTimeout(() => this.showSuccessToast = false, 3000);
        },
        showSuccessNoteNotification() {
            this.showSuccessNoteToast = true;
            setTimeout(() => this.showSuccessNoteToast = false, 3000);
        },
        toggleEvents() {
            this.eventsExpanded = !this.eventsExpanded;
        },
        translateEventType(eventType) {
            switch (eventType) {
                case 'DEPOSIT':
                    return 'DÉPÔT';
                case 'WITHDRAW':
                    return 'RETRAIT';
                default:
                    return eventType;
            }
        },
        getTypeClass(eventType) {
            switch (eventType) {
                case 'DEPOSIT':
                    return 'text-success';
                case 'WITHDRAW':
                    return 'text-warning';
                default:
                    return 'text-secondary';
            }
        },
        getBankAccount(id) {
            this.isLoading = true;
            fetch({ url: `bank-accounts/${id}` })
                .then(({ data }) => {
                    this.bankAccount.data = data;
                    this.bankAccount.isError = false;
                    this.bankAccount.isLoading = false;

                })
                .catch(() => {
                    this.bankAccount.isError = true;
                    this.bankAccount.errorMessage = "Une erreur est survenue, réessayez";
                })
                .finally(() => {
                    this.bankAccount.isLoading = false;
                });
        },

        saveDepense() {
            const formData = new FormData();
            formData.append('bank_account_id', this.$route.params.id);
            formData.append('transaction_type', "WITHDRAW");
            formData.append('amount', this.amount);
            formData.append('description', this.description);

            if (this.image) {
                formData.append('image', this.image);
            }

            fetch({ url: `bank-accounts-transactions`, method: 'POST', data: formData })
                .then(response => {

                    this.amount = "";
                    this.description = "";
                    this.showModal = false;
                    this.image = "";
                    this.showSuccessNotification();
                    this.getBankAccount(this.$route.params.id);
                })
                .catch(error => {
                    console.error(error);
                    alert("Une erreur est survenue.");
                });
        },

        saveEntree() {
            const formData = new FormData();
            formData.append('bank_account_id', this.$route.params.id);
            formData.append('transaction_type', "DEPOSIT");
            formData.append('amount', this.amount);
            formData.append('description', this.description);

            if (this.image) {
                formData.append('image', this.image);
            }

            fetch({ url: `bank-accounts-transactions`, method: 'POST', data: formData })
                .then(response => {

                    this.amount = "";
                    this.description = "";
                    this.image = "";
                    this.showModal = false;
                    this.showSuccessNotification();
                    this.getBankAccount(this.$route.params.id);
                })
                .catch(error => {
                    console.error(error);
                    alert("Une erreur est survenue.");
                });
        },

        showUpdateSuccessNotification() {
            this.showUpdateSuccessToast = true;
            setTimeout(() => this.showUpdateSuccessToast = false, 3000);
        },
        openEditModal(event) {
            this.editingEvent = { ...event };
            this.showEditModal = true;
        },
        handleEditImageUpload(event) {
            const file = event.target.files[0];
            this.editingEvent.image = file; // Stocke l'image sélectionnée
        },
        updateEvent() {
            try {
                const formData = new FormData();
                formData.append('amount', this.editingEvent.amount);
                formData.append('description', this.editingEvent.description);
                formData.append('_method', "PUT");

                if (this.editingEvent.image) {
                    formData.append('image', this.editingEvent.image);
                }

                fetch({ url: `bank-accounts-transactions/${this.editingEvent.id}`, method: 'POST', data: formData })
                    .then(response => {
                        this.showEditModal = false;
                        this.showUpdateSuccessNotification();
                        this.getBankAccount(this.$route.params.id);
                    })
                    .catch(error => {
                        console.error(error);
                        alert("Une erreur est survenue.");
                    });
                
            } catch (error) {
                console.error("Erreur de mise à jour :", error);
                alert("Une erreur est survenue lors de la mise à jour de l'événement.");
            }
        }
        
    }
}
</script>

<style scoped>
.card {
    border: 1px solid #ddd;
    border-radius: 8px;
}

.card-body {
    padding: 20px;
}

.text-success {
    color: green;
    font-weight: bold;
}

.text-danger {
    color: red;
    font-weight: bold;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    font-size: 18px;
    color: #555;
}

.spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.table {
    margin-top: 20px;
}

.building-info {
    text-align: right;
}

.card-header {
    font-weight: bold;
}

.header-title {
    font-size: 1.5rem;
}

.text-success {
    color: #28a745;
}

.text-danger {
    color: #dc3545;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    align-items: center;
    justify-content: center;
}

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    flex-basis: 600px;
    padding: spacing(4);

    border-radius: 0%;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-btn {
    background: none;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.custom-toast {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #28a745;
    /* Couleur de fond verte */
    color: white;
    /* Couleur du texte */
    padding: 15px 30px;
    /* Espacement interne */
    border-radius: 8px;
    /* Coins arrondis */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    /* Ombre portée */
    animation: fadeOut 3s forwards;
    /* Animation de disparition */
    font-size: 1.1em;
    /* Taille de police */
    text-align: center;
    /* Alignement du texte */
    max-width: 80%;
    /* Largeur maximale */
    width: 300px;
    /* Largeur fixe */
    z-index: 9999;
    /* S'assurer qu'il est au-dessus des autres éléments */
    transition: transform 0.3s ease;
    /* Transition pour l'apparition */
    transform: translate(-50%, -50%) scale(1.05);
    /* Légère échelle pour l'effet */
}

/* Effet de sortie */
@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.8);
    }
}

/* Ajouter un effet au survol */
.custom-toast:hover {
    cursor: pointer;
    background-color: #218838;
    /* Couleur légèrement plus foncée au survol */
}
</style>