<template>


    <main class="main h-100 w-100">
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                    <div class="d-table-cell align-middle">

                        <div class="text-center mt-4">
                            <h1 class="h2">Bienvenue sur</h1>
                            <p class="lead">
                                Inscrivez-vous  pour continuer
                            </p>
                        </div>
                        <form @submit.prevent="handleSubmit">


                            <div class="card">
                                <div class="card-body">

                                    <div class="m-sm-4">
                                        <div class="text-center">
                                            <img src="@/assets/logo.jpg" alt="A" class="img-fluid rounded-circle"
                                                width="132" height="132" />
                                        </div>
                                        <div class="alert alert-danger alert-dismissible mt-4" role="alert"
                                            v-if="showMessage && !isSuccess">
                                            <div class="alert-message">
                                                <strong>Désolé!, </strong> {{ message }}
                                            </div>

                                            <button type="button" class="btn-close btn-close-white"
                                                data-bs-dismiss="alert" aria-label="Close">
                                            </button>
                                        </div>

                                        <div class="mb-3">
    											<label>Name</label>
    											<input class="form-control form-control-lg" type="text" v-model="form.name" name="name" placeholder="Enter your name" />
    										</div>
    										<div class="mb-3">
    											<label>Company</label>
    											<input class="form-control form-control-lg" type="text" v-model="form.company" name="company" placeholder="Enter your company name" />
    										</div>
    										<div class="mb-3">
    											<label>Email</label>
    											<input class="form-control form-control-lg" type="email" v-model="form.email" name="email" placeholder="Enter your email" />
    										</div>
    										<div class="mb-3">
    											<label>Password</label>
    											<input class="form-control form-control-lg" type="password" v-model="form.password" name="password" placeholder="Enter password" />
    										</div>
    										<div class="mb-3">
    											<label>Confirm</label>
    											<input class="form-control form-control-lg" type="password" v-model="form.confirm" name="password" placeholder="Confirm password" />
    										</div>

                                        <div class="text-center mt-3">
                                            <button class="btn btn-lg btn-primary" type="submit" v-if="!loading">S'Inscrire</button>
                                            <div class="btn btn-lg btn-dark" v-else>En cours ...</div>
                                            <div class=" mt-3">
                                                <router-link :to="{name:'Login'}">Vous avez un compte  ?Connectez-vous</router-link>
                                            </div>
                                            <!-- <button type="submit" class="btn btn-lg btn-primary">Sign in</button> -->
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </main>


</template>

<script>

import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
    name: 'LoginPage',
    data() {
        return {
            form:{
                name:null,
                company:null,
                password:null,
                confirm:null,

            }
        
        }
    },
    computed: {
        ...mapFields("register", {
            loading: "isLoading",
            message: "message",
            showMessage: "showMessage",
            isSuccess: "isSuccess"

        })
    },
    methods: {
        ...mapActions({
            submitForm: 'register/handleRegister'
        }),
        handleSubmit() {

            this.submitForm(this.form)

        },
        handleKeyPress(event) {
            if (event.keyCode === 13) {
                this.handleSubmit();
            }
        }
    },
    mounted() {
        // Écouter l'événement keydown sur les champs de formulaire
        document.addEventListener('keydown', this.handleKeyPress);
    },
    beforeUnmount() {
        // Supprimer l'écouteur d'événement lors de la destruction du composant
        document.removeEventListener('keydown', this.handleKeyPress);
    }
}
</script>

<style></style>