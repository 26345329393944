<template>
  <div v-if="isLoading"
    style="background-color: white;position: absolute;width: 100vw;height: 100vh;display: flex;flex-direction: column;justify-content: center;align-items: center;z-index: 90;">
    Patientez</div>
  <router-view></router-view>


</template>

<script>

import "@/assets/js/app";
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'App',
  
  mounted() {
    this.checkToken()
  },
  computed: {
    ...mapFields("auth", {
      isLoading: "checkIsLoading",

    })
  },
  methods: {
    ...mapActions({
      checkToken: 'auth/checkToken'
    })
  }

}
</script>


<style>
</style>
